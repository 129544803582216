/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PWAPrompt from "react-ios-pwa-prompt";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { usePWAInstall } from "react-use-pwa-install";
import * as dateFns from "date-fns";

// region Imports - Assets
import IconFilterDashboard from "@assets/icons/filtro-dashboard.svg";
import InstallPWADesktopIcon from "@assets/icons/install-pwa-desktop.svg";
import IconVehiclesInMaintenance from "@assets/icons/manutencao.svg";
import IconMapAllDashboard from "@assets/icons/map-all-dashboard.svg";
import IconNotifications from "@assets/icons/notificacao.svg";
import IconImproperDischarge from "@assets/icons/betoneira-descarregamento_indevido.svg";
import IconTrustedLocations from "@assets/icons/truted-locations.svg";
import IconGenericVehicleState from "@assets/stateIcons/generic.svg";
import HeaderLogo from "@assets/FleetLogo/headerLogo.svg";
import MenuLogo from "@assets/FleetLogo/menuLogo.svg";
import UserNoPhoto from "@assets/user-no-photo.png";
import { ReactComponent as ClosedLock } from "@assets/icons/closedLock.svg";
import IconConcreteExpiration from "@assets/icons/concrete-expiration.svg";
import IconConcreteAboutToExpire from "@assets/icons/concrete-about-to-expire.svg";
import IconConcreteExpired from "@assets/icons/concrete-expired.svg";
import { ParamsKeys, ParamsNames } from "@shared/constants/params-names.enum";
import IconAlertSpeedTravel from "@assets/icons/alerts/alert-speed-travel.svg";
// endregion Imports - Assets
// region Imports - Utils
import * as _ from "lodash";
import classNames from "classnames";
// endregion Imports - Utils
// region Imports - Material-UI
import { Backdrop, Badge, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import NotificationIcon from "@material-ui/icons/ErrorOutline";
import SpeedIcon from "@material-ui/icons/Speed";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import HelpIcon from "@material-ui/icons/Help";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExploreIcon from "@material-ui/icons/Explore";
import HomeIcon from "@material-ui/icons/Home";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MenuIcon from "@material-ui/icons/Menu";
import PeopleIcon from "@material-ui/icons/People";
import SimCardIcon from "@material-ui/icons/SimCard";
import SettingsIcon from "@material-ui/icons/Settings";
import RemoveFromQueueIcon from "@material-ui/icons/RemoveFromQueue";
import RoomIcon from "@material-ui/icons/Room";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import GroupWork from "@material-ui/icons/GroupWork";
import Workspaces from "@mui/icons-material/Workspaces";
import Receipt from "@mui/icons-material/Receipt";

import InstallPWAMobileIcon from "@material-ui/icons/SystemUpdate";
// endregion Imports - Material-UI
// region Imports - Shared
import { Param } from "@shared/entities/reg_params.entity";
import { AlertTypesID } from "@shared/constants/alert-types.enum";
// endregion Imports - Shared
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Unform
// endregion Imports - Unform
// region Imports - Atoms
import Survey from "@atoms/Survey";
// endregion Imports - Atoms
// region Imports - Components
import ModalContactUs from "@components/Modal/ContactUs";
import LanguageIcon from "@components/LanguageIcon";
import DialogConfirmAction from "@components/Dialog/ConfirmAction";
// endregion Imports - Components
/* region Imports - Molecules */
import ModalHelp from "src/molecules/ModalHelp";
import ModalMapAllVehicles from "@molecules/ModalMapAllVehiclesProps";
import PopperAlerts from "@molecules/PopperAlerts";
import PopperVehiclesInMaintenance from "@molecules/PopperVehiclesInMaintenance";
import PopperVehiclesInSafePlace from "@molecules/PopperVehiclesInSafePlace";
import PopperFilterVehiclesDashboard from "@molecules/PopperFilterVehiclesDashboard";
import PopperImproperDischarge from "@molecules/PopperImproperDischarge";
import PopperBlockedVehicles from "@molecules/PopperBlockedVehicles";
import PopperConcreteExpiration from "@molecules/PopperConcreteExpiration";
import PopperSpeedingAlerts from "@molecules/PopperSpeedingAlerts";
/* endregion Imports - Molecules */
// region Imports - Hooks
import { useAuth } from "@hooks/useAuth";
import { useToast } from "@hooks/useToast";
import { useGetter } from "@hooks/useGetter";
// endregion Imports - Hooks
// region Imports - Store
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import { MergeVehicleStatesLists, VehiclesStatesObj } from "@store/ducks/Vehicles/VehiclesStates/vehicles-states.type";
import { AlertsStateObj } from "@store/ducks/Alerts/alerts.type";
import { SurveyPlatform } from "@store/global.enum";
// endregion Imports - Store
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import { AlertMessages, GlobalMessages, MenuMessages, ToastMessages } from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Package Json
import packageJson from "../../../package.json";
// endregion Imports - Package Json
// region Imports - Styles
import { Container, ContainerSVGMenuIcon } from "./styles";
// endregion Imports - Styles

// region Interfaces
interface Menus {[menuBlockName: string]: MenusItens[]}

interface MenusItens {
  id: string,
  icon: any,
  iconMobile?: any,
  text: string,
  link: string,
  isPrivate: boolean,
  isSuperPrivate: boolean,
  component?: string
}

interface Poppers {
  vehiclesInMaintenance: boolean,
  improperDischargesVehicles: boolean
  vehiclesInSafePlace: boolean;
  alertsVehicles: boolean;
  filterVehiclesDashboard: boolean;
  blockedVehicles: boolean;
  concreteExpiration: boolean;
  speedingAlertsVehicles: boolean;
}

// endregion Interfaces
// region Constants
// Menu IDs
const menuTimeLineId = "MenuTimeline";
const menuModulesDriversId = "MenuModulesDrivers";
const menuModulesVehiclesId = "MenuModulesVehicles";
const menuModulesLocationsId = "MenuModulesLocations";
const menuModulesGroupsId = "menuModulesGroups";
const menuModulesRegionalsId = "menuModulesRegionals";
const menuModulesPumpProgrammingId = "menuModulesPumpProgramming";
const menuManagementConfigurationsId = "MenuManagementConfigurations";

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: 1100 }
}));

// endregion Constants

type HeaderProps = { title?: string; };

const Header: React.FC<HeaderProps> = ({ title }) => {

  // region Hooks
  const style = useStyles();

  const { signOut, user, params } = useAuth();

  const { addToast } = useToast();
  const screen = useSelector(({ screen: state }) => state);
  const filtersDashboard = useSelector(({ filtersDashboard: state }) => state);
  const vehiclesStates = useSelector(({ vehiclesStates: state }) => state);
  const alertsStates = useSelector(({ alerts: state }) => state);
  const aggregateTrucksStates = useSelector(({ aggregateTrucksStates: state }) => state);

  const { t } = useTranslation();

  // PWA Actions
  const handleInstallPWA = usePWAInstall();
  // endregion Hooks
  // region States
  const [showPromptIOSPWA, setShowPromptIOSPWA] = useState(false);

  const [alerts, setAlerts] = useState<AlertsStateObj>(alertsStates.all);
  const [isBlockDay, setIsBlockDay] = useState(false);

  const [vehicles, setVehicles] = useState<VehiclesStatesObj>(vehiclesStates.all);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModalMapAllVehicles, setOpenModalMapAllVehicles] = useState(false);
  const [openContactUs, setOpenContactUs] = useState(false);
  const [openModalIconLegend, setOpenModalIconLegend] = useState(false);
  const [daysOfBlock, setDaysOfBlock] = useState(10);

  const [openPoppers, setOpenPoppers] = useState<Poppers>({
    vehiclesInMaintenance: false,
    vehiclesInSafePlace: false,
    alertsVehicles: false,
    improperDischargesVehicles: false,
    filterVehiclesDashboard: false,
    blockedVehicles: false,
    concreteExpiration: false,
    speedingAlertsVehicles: false
  });
  const getOpenPoppers = useGetter(openPoppers);
  // endregion States
  // region Constants

  // region Menus

  const menus: Menus = {
    [t(MenuMessages.dashboards)]: [
      {
        id: "MenuMain",
        icon: <HomeIcon />,
        text: t(MenuMessages.production),
        link: "/main",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: menuTimeLineId,
        icon: <HomeIcon />,
        text: t(MenuMessages.pumps),
        link: "/timeline",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: "MenuSupportVehicle",
        icon: <HomeIcon />,
        text: t(MenuMessages.supportVehicles),
        link: "/support-vehicles",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: "MenuAggregateTrucks",
        icon: <HomeIcon />,
        text: t(MenuMessages.trucks),
        link: "/aggregate-trucks",
        isPrivate: false,
        isSuperPrivate: false
      }
    ],
    [t(MenuMessages.management)]: [
      {
        id: "MenuManagementUsers",
        icon: <PeopleIcon />,
        text: t(MenuMessages.users),
        link: "/management/users",
        isPrivate: true,
        isSuperPrivate: false
      }
    ],
    [t(MenuMessages.modules)]: [
      {
        id: menuModulesDriversId,
        icon: <AssignmentIndIcon />,
        text: t(MenuMessages.drivers),
        link: "/modules/drivers",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: menuModulesVehiclesId,
        icon: <LocalShippingIcon />,
        text: t(MenuMessages.vehicles),
        link: "/modules/vehicles",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: menuModulesLocationsId,
        icon: <RoomIcon />,
        text: t(MenuMessages.locations),
        link: "/modules/locations",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: menuModulesGroupsId,
        icon: <Workspaces />,
        text: t(MenuMessages.groups),
        link: "/modules/groups",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: menuModulesRegionalsId,
        icon: <GroupWork />,
        text: t(MenuMessages.regionals),
        link: "/modules/regionals",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: "MenuModulesHardwares",
        icon: <RemoveFromQueueIcon />,
        text: t(MenuMessages.hardwares),
        link: "/modules/hardwares",
        isPrivate: true,
        isSuperPrivate: true
      },
      {
        id: "MenuModulesSimcards",
        icon: <SimCardIcon />,
        text: t(MenuMessages.SIMCards),
        link: "/modules/simcards",
        isPrivate: true,
        isSuperPrivate: true
      },
      {
        id: menuModulesPumpProgrammingId,
        icon: <Receipt />,
        text: t(MenuMessages.pumpProgramming),
        link: "/modules/pump-programming",
        isPrivate: false,
        isSuperPrivate: false
      }
    ],
    [t(MenuMessages.reports)]: [
      {
        id: "MenuScheduledReports",
        icon: <AccessAlarmIcon />,
        text: t(MenuMessages.scheduledReports),
        link: "/reports/scheduled-reports",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: "MenuReportsTravelsHistory",
        icon: <ExploreIcon />,
        text: t(MenuMessages.travelHistory),
        link: "/reports/travels-history",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: "MenuReportsAlertsHistory",
        icon: <NotificationIcon />,
        text: t(MenuMessages.alertHistory),
        link: "/reports/alerts-history",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: "MenuReportsCoordinateAnalysis",
        icon: <CallSplitIcon />,
        text: t(MenuMessages.coordinateAnalysis),
        link: "/reports/coordinate-analysis",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: "MenuReportsVehiclesHistory",
        icon: <LocalShippingIcon />,
        text: t(MenuMessages.vehicleHistory),
        link: "/reports/vehicles-history",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: "MenuReportsAverageSpeed",
        icon: <SpeedIcon />,
        text: t(MenuMessages.averageSpeed),
        link: "/reports/average-speed",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: "MenuReportsVehicleStatesHistory",
        icon: <ContainerSVGMenuIcon><ReactSVG src={IconGenericVehicleState} /></ContainerSVGMenuIcon>,
        text: t(MenuMessages.vehicleStatesHistory),
        link: "/reports/vehicle-states-history",
        isPrivate: false,
        isSuperPrivate: false
      }
    ],
    [t(MenuMessages.others)]: [
      {
        id: "MenuContactContactUs",
        icon: <ContactSupportIcon />,
        text: t(MenuMessages.contactUs),
        link: "",
        isPrivate: false,
        isSuperPrivate: false,
        component: "modalContactUs"
      },
      {
        id: menuManagementConfigurationsId,
        icon: <SettingsIcon />,
        text: t(MenuMessages.settings),
        link: "/settings",
        isPrivate: false,
        isSuperPrivate: false
      },
      {
        id: "MenuInstallPWA",
        icon: <ContainerSVGMenuIcon><ReactSVG src={InstallPWADesktopIcon} /></ContainerSVGMenuIcon>,
        iconMobile: <InstallPWAMobileIcon />,
        text: t(MenuMessages.installApp),
        link: "",
        isPrivate: false,
        isSuperPrivate: false,
        component: "installPWA"
      }
    ]
  };

  // endregion Menus
  // Separation of 'Improper Discharge', 'Blocked Vehicles' and 'Concrete Expiration' related alerts of others
  const allAlerts = alerts.list.filter(
    (alert) => alert.type.id_alert_type !== AlertTypesID.DESCARREGAMENTO_INDEVIDO
      && alert.type.id_alert_type !== AlertTypesID.BLOQUEIO
      && alert.type.id_alert_type !== AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO
      && alert.type.id_alert_type !== AlertTypesID.CONCRETO_VENCIDO
      && alert.type.id_alert_type !== AlertTypesID.EXCESSO_DE_VELOCIDADE
      && alert.type.id_alert_type !== AlertTypesID.EXCESSO_DE_VELOCIDADE_VIAGEM
  );

  const speedingAlerts = alerts.list.filter(
    (alert) => alert.type.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE
      || alert.type.id_alert_type === AlertTypesID.EXCESSO_DE_VELOCIDADE_VIAGEM
  );

  const improperDischargeAlerts = alerts.list.filter(
    (alert) => alert.type.id_alert_type === AlertTypesID.DESCARREGAMENTO_INDEVIDO
  );

  const concreteExpirationAlerts = alerts.list.filter(
    (alert) => alert.type.id_alert_type === AlertTypesID.CONCRETO_PROXIMO_AO_VENCIMENTO
      || alert.type.id_alert_type === AlertTypesID.CONCRETO_VENCIDO
  );

  const concreteExpiredAlerts = alerts.list.filter(
    (alert) => alert.type.id_alert_type === AlertTypesID.CONCRETO_VENCIDO
  );
  // endregion Constants
  // region Functions

  /**
   * Check what popper has to open
   * @param popper
   */
  const handlePopper = useCallback((popper: "vehiclesInMaintenance" | "vehiclesInTrustedLocations"
    | "alertsVehicles" | "improperDischargesVehicles" | "filterVehiclesDashboard" | "blockedVehicles" | "concreteExpiration"
    | "closeAll" | "speedingAlertsVehicles") => {

    document.body.classList.add("noScroll");

    const FcloseAll = () => {

      setOpenPoppers({
        vehiclesInMaintenance: false,
        alertsVehicles: false,
        improperDischargesVehicles: false,
        vehiclesInSafePlace: false,
        filterVehiclesDashboard: false,
        blockedVehicles: false,
        concreteExpiration: false,
        speedingAlertsVehicles: false
      });

      document.body.classList.remove("noScroll");
    };

    switch (popper) {
    case "vehiclesInMaintenance":
      if (getOpenPoppers().vehiclesInMaintenance) FcloseAll();
      else {
        setOpenPoppers({
          alertsVehicles: false,
          filterVehiclesDashboard: false,
          vehiclesInSafePlace: false,
          improperDischargesVehicles: false,
          vehiclesInMaintenance: true,
          blockedVehicles: false,
          concreteExpiration: false,
          speedingAlertsVehicles: false
        });
      }
      break;
    case "vehiclesInTrustedLocations":
      if (getOpenPoppers().vehiclesInSafePlace) FcloseAll();
      else {
        setOpenPoppers({
          vehiclesInMaintenance: false,
          alertsVehicles: false,
          filterVehiclesDashboard: false,
          improperDischargesVehicles: false,
          vehiclesInSafePlace: true,
          blockedVehicles: false,
          concreteExpiration: false,
          speedingAlertsVehicles: false
        });
      }
      break;
    case "alertsVehicles":
      if (getOpenPoppers().alertsVehicles) FcloseAll();
      else {
        setOpenPoppers({
          vehiclesInMaintenance: false,
          filterVehiclesDashboard: false,
          vehiclesInSafePlace: false,
          improperDischargesVehicles: false,
          alertsVehicles: true,
          blockedVehicles: false,
          concreteExpiration: false,
          speedingAlertsVehicles: false
        });
      }
      break;
    case "improperDischargesVehicles":
      if (getOpenPoppers().improperDischargesVehicles) FcloseAll();
      else {
        setOpenPoppers({
          vehiclesInMaintenance: false,
          filterVehiclesDashboard: false,
          vehiclesInSafePlace: false,
          alertsVehicles: false,
          improperDischargesVehicles: true,
          blockedVehicles: false,
          concreteExpiration: false,
          speedingAlertsVehicles: false
        });
      }
      break;
    case "filterVehiclesDashboard":
      if (getOpenPoppers().filterVehiclesDashboard) FcloseAll();
      else {
        setOpenPoppers({
          vehiclesInMaintenance: false,
          alertsVehicles: false,
          vehiclesInSafePlace: false,
          improperDischargesVehicles: false,
          filterVehiclesDashboard: true,
          blockedVehicles: false,
          concreteExpiration: false,
          speedingAlertsVehicles: false
        });
      }
      break;
    case "blockedVehicles":
      if (getOpenPoppers().blockedVehicles) FcloseAll();
      else {
        setOpenPoppers({
          vehiclesInMaintenance: false,
          filterVehiclesDashboard: false,
          vehiclesInSafePlace: false,
          improperDischargesVehicles: false,
          alertsVehicles: false,
          blockedVehicles: true,
          concreteExpiration: false,
          speedingAlertsVehicles: false
        });
      }
      break;
    case "concreteExpiration":
      if (getOpenPoppers().concreteExpiration) FcloseAll();
      else {
        setOpenPoppers({
          vehiclesInMaintenance: false,
          filterVehiclesDashboard: false,
          vehiclesInSafePlace: false,
          improperDischargesVehicles: false,
          alertsVehicles: false,
          blockedVehicles: false,
          concreteExpiration: true,
          speedingAlertsVehicles: false
        });
      }
      break;
    case "speedingAlertsVehicles":
      if (getOpenPoppers().concreteExpiration) FcloseAll();
      else {
        setOpenPoppers({
          vehiclesInMaintenance: false,
          filterVehiclesDashboard: false,
          vehiclesInSafePlace: false,
          improperDischargesVehicles: false,
          alertsVehicles: false,
          blockedVehicles: false,
          concreteExpiration: false,
          speedingAlertsVehicles: true
        });
      }
      break;
    default:
      FcloseAll();
      break;

    }

  }, [getOpenPoppers]);

  /**
   * Checks if there is a menu in the parameter block that can be displayed
   * @param menuBlock
   */
  const verifyIfShowMenuBlock = (menuBlock: MenusItens[]) => {

    return menuBlock.some((menu) => {
      return (!menu.isPrivate || user.admin) && userHasPermissionInMenu(menu);
    });
  };

  /**
   * Get the permission of the user to access the specific menu
   * @param menu Menu to check permission
   */
  const userHasPermissionInMenu = (menu: MenusItens) => {

    if (menu.id === menuModulesDriversId) return user.allowed_change_drivers;
    if (menu.id === menuModulesVehiclesId) return user.allowed_change_vehicles;
    if (menu.id === menuModulesLocationsId) return user.allowed_change_locations;
    if (menu.id === menuManagementConfigurationsId) return user.allowed_change_params;
    if (menu.id === menuTimeLineId) return getPumpTimelineEnabled();

    return true;
  };

  /**
   * Function to get the latest value of the pump timeline enabled param
   */
  const getPumpTimelineEnabled = () => {
    return params?.find(
      (param) => param.name === ParamsNames.PUMP_TIMELINE_ENABLED
    )?.key === ParamsKeys.PUMP_TIMELINE_ENABLED_TRUE;
  };

  /**
   * Render Alert block date
   */
  const renderAlert = useCallback(() => {

    const limitDate = dateFns.format(dateFns.addDays(new Date(), Number(daysOfBlock)), "dd/MM/yyyy");
    const htmlAlertMessage = t(AlertMessages.intervalOfDays, { days: Number(daysOfBlock) + 1, limitDate });

    return (
      <>
        {daysOfBlock < 7 && daysOfBlock >= 0 && (<p dangerouslySetInnerHTML={{ __html: htmlAlertMessage }} />)}
        {daysOfBlock < 0 && (<p>{`${t(AlertMessages.blockedPlataforom)} `}</p>)}
      </>
    );

  }, [daysOfBlock, t]);

  /**
   * App bar components (DESKTOP and MOBILE) => Render according screen size
   */
  const renderAppBarDesktop = useCallback(() => (
    <AppBar
      position="fixed"
      className="appBarDesktop"
    >
      <div className="alertBlockDay">
        {renderAlert()}
      </div>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={() => setOpenDrawer(true)}
          edge="start"
          id="menuIcon"
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap className="title">{title}</Typography>
        <div className="container">
          <div className="select" />
          <div className="actions">
            <IconButton
              disableRipple
              id="actionPopperVehiclesInMaintenance"
              onClick={() => handlePopper("vehiclesInMaintenance")}
            >
              <Badge
                invisible={vehicles.list["Veiculos em manutencao"] && vehicles.list["Veiculos em manutencao"].length
                  === 0}
                badgeContent={vehicles.list["Veiculos em manutencao"]
                  && vehicles.list["Veiculos em manutencao"].length}
                color="secondary"
              >
                <ReactSVG fill="blue" src={IconVehiclesInMaintenance} />
              </Badge>
            </IconButton>
            <IconButton
              disableRipple
              id="actionPopperVehiclesInSafePlace"
              onClick={() => handlePopper("vehiclesInTrustedLocations")}
            >
              <Badge
                invisible={vehicles.list["Em local confiavel"] && vehicles.list["Em local confiavel"].length === 0}
                badgeContent={vehicles.list["Em local confiavel"] && vehicles.list["Em local confiavel"].length}
                color="secondary"
              >
                <ReactSVG className="iconTrustedLocations" fill="blue" src={IconTrustedLocations} />
              </Badge>
            </IconButton>
            <IconButton disableRipple id="actionPopperAlerts" onClick={() => handlePopper("alertsVehicles")}>
              <Badge
                invisible={allAlerts && allAlerts.length === 0}
                badgeContent={allAlerts && allAlerts.length}
                color="secondary"
              >
                <ReactSVG className="iconNotifications" src={IconNotifications} />
              </Badge>
            </IconButton>
            {improperDischargeAlerts && improperDischargeAlerts.length > 0 && (
              <IconButton
                disableRipple
                id="actionPopperImproperDischarge"
                onClick={() => handlePopper("improperDischargesVehicles")}
              >
                <Badge
                  badgeContent={improperDischargeAlerts.length}
                  color="secondary"
                >
                  <ReactSVG className="iconImproperDischarge" src={IconImproperDischarge} />
                </Badge>
              </IconButton>
            )}
            <IconButton
              disableRipple
              id="actionPopperFilterVehiclesDashboard"
              onClick={() => handlePopper("filterVehiclesDashboard")}
            >
              <ReactSVG
                className={classNames("iconFilterDashboard", filtersDashboard.active && "active")}
                src={IconFilterDashboard}
              />
            </IconButton>
            <IconButton disableRipple onClick={() => setOpenModalMapAllVehicles(true)}>
              <ReactSVG className="iconFilterDashboard" src={IconMapAllDashboard} />
            </IconButton>
            <IconButton
              disableRipple
              id="actionPopperConcreteExpiration"
              onClick={() => handlePopper("concreteExpiration")}
            >
              <ReactSVG
                src={
                  (concreteExpirationAlerts.length === 0) ? IconConcreteExpiration
                    : concreteExpiredAlerts.length > 0 ? IconConcreteExpired
                      : IconConcreteAboutToExpire
                }
              />
            </IconButton>
            {vehicles.list["Veiculos bloqueados"] && vehicles.list["Veiculos bloqueados"].length > 0 && (
              <IconButton
                disableRipple
                id="actionPopperBlockedVehicles"
                onClick={() => handlePopper("blockedVehicles")}
              >
                <Badge
                  badgeContent={vehicles.list["Veiculos bloqueados"] && vehicles.list["Veiculos bloqueados"].length}
                  color="secondary"
                >
                  <ClosedLock className="blockedVehiclesIcon" color="#FF0000" height="1.5rem" />
                </Badge>
              </IconButton>
            )}
            <IconButton
              disableRipple
              id="actionPopperSpeedingAlerts"
              onClick={() => handlePopper("speedingAlertsVehicles")}
            >
              <Badge
                invisible={speedingAlerts && speedingAlerts.length === 0}
                badgeContent={speedingAlerts && speedingAlerts.length}
                color="secondary"
              >
                <ReactSVG
                  src={IconAlertSpeedTravel}
                  className={speedingAlerts?.length > 0 ? "red-fill" : "gray-fill"}
                />
              </Badge>
            </IconButton>
          </div>
          <div className="logo_and_language_container">
            <div className="logo" role="presentation" onClick={() => setOpenContactUs(true)}>
              <ReactSVG className="logoSVG" src={HeaderLogo} />
            </div>
            <LanguageIcon inHeader />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  ), [
    title,
    vehicles.list,
    allAlerts,
    improperDischargeAlerts,
    filtersDashboard.active,
    handlePopper,
    concreteExpirationAlerts,
    concreteExpiredAlerts,
    speedingAlerts,
    renderAlert
  ]);
  const renderAppBarMobile = useCallback(() => (
    <AppBar
      position="fixed"
      className="appBarMobile"
    >
      <div className="alertBlockDay">
        {renderAlert()}
      </div>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => setOpenDrawer(true)}
          id="menuIcon"
        >
          <MenuIcon />
        </IconButton>
        <div className="actions">
          <IconButton
            disableRipple
            id="actionPopperVehiclesInMaintenance"
            onClick={() => handlePopper("vehiclesInMaintenance")}
          >
            <Badge
              invisible={vehicles.list["Veiculos em manutencao"] && vehicles.list["Veiculos em manutencao"].length
                === 0}
              badgeContent={vehicles.list["Veiculos em manutencao"] && vehicles.list["Veiculos em manutencao"].length}
              color="secondary"
            >
              <ReactSVG fill="blue" src={IconVehiclesInMaintenance} />
            </Badge>
          </IconButton>
          <IconButton
            disableRipple
            id="actionPopperVehiclesInSafePlace"
            onClick={() => handlePopper("vehiclesInTrustedLocations")}
          >
            <Badge
              invisible={vehicles.list["Em local confiavel"] && vehicles.list["Em local confiavel"].length === 0}
              badgeContent={vehicles.list["Em local confiavel"] && vehicles.list["Em local confiavel"].length}
              color="secondary"
            >
              <ReactSVG className="custom" src={IconTrustedLocations} />
            </Badge>
          </IconButton>
          <IconButton disableRipple id="actionPopperAlerts" onClick={() => handlePopper("alertsVehicles")}>
            <Badge
              invisible={allAlerts && allAlerts.length === 0}
              badgeContent={allAlerts && allAlerts.length}
              color="secondary"
            >
              <ReactSVG className="iconNotifications" src={IconNotifications} />
            </Badge>
          </IconButton>
          <IconButton
            disableRipple
            id="actionPopperFilterVehiclesDashboard"
            onClick={() => handlePopper("filterVehiclesDashboard")}
          >
            <ReactSVG
              className={classNames("iconFilterDashboard", filtersDashboard.active && "active")}
              src={IconFilterDashboard}
            />
          </IconButton>
          <IconButton disableRipple onClick={() => setOpenModalMapAllVehicles(true)}>
            <ReactSVG className="iconFilterDashboard" src={IconMapAllDashboard} />
          </IconButton>
          {improperDischargeAlerts && improperDischargeAlerts.length > 0 && (
            <IconButton
              disableRipple
              id="actionPopperImproperDischarge"
              onClick={() => handlePopper("improperDischargesVehicles")}
            >
              <Badge
                badgeContent={improperDischargeAlerts.length}
                color="secondary"
              >
                <ReactSVG className="iconImproperDischarge" src={IconImproperDischarge} />
              </Badge>
            </IconButton>
          )}
          <IconButton
            disableRipple
            id="actionPopperConcreteExpiration"
            onClick={() => handlePopper("concreteExpiration")}
          >
            <ReactSVG
              src={
                (concreteExpirationAlerts.length === 0) ? IconConcreteExpiration
                  : concreteExpiredAlerts.length > 0 ? IconConcreteExpired
                    : IconConcreteAboutToExpire
              }
            />
          </IconButton>
          {vehicles.list["Veiculos bloqueados"] && vehicles.list["Veiculos bloqueados"].length > 0 && (
            <IconButton
              disableRipple
              id="actionPopperBlockedVehicles"
              onClick={() => handlePopper("blockedVehicles")}
            >
              <Badge
                badgeContent={vehicles.list["Veiculos bloqueados"] && vehicles.list["Veiculos bloqueados"].length}
                color="secondary"
              >
                <ClosedLock className="blockedVehiclesIcon" color="#FF0000" height="1.5rem" />
              </Badge>
            </IconButton>
          )}
        </div>
        <LanguageIcon inHeader />
      </Toolbar>
    </AppBar>
  ), [
    vehicles.list,
    allAlerts,
    improperDischargeAlerts,
    filtersDashboard.active,
    handlePopper,
    concreteExpirationAlerts,
    concreteExpiredAlerts,
    renderAlert
  ]);

  /**
   * Render left drawer bar
   */
  const renderDrawer = () => {

    return (
      <Drawer className="drawer" anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <div className="container">
          <div className="header">
            <div className="closeIcon" onClick={() => setOpenDrawer(false)} aria-hidden="true"><ArrowBackIcon /></div>
            <div className="logo">
              <span className="client-beta-name">{location.hostname.split(".")[0] === "beta"
                && process.env.REACT_APP_BETA_CLIENT}
              </span>
              <ReactSVG src={MenuLogo} />
              <span>V.{packageJson.version}</span>
            </div>
          </div>
          <div className="help">
            <List>
              <ListItem
                button
                disableRipple
                onClick={() => setOpenModalIconLegend(true)}
                aria-hidden="true"
              >
                <div className="helpItem">
                  <ListItemIcon><HelpIcon /></ListItemIcon>
                  <ListItemText primary={t(MenuMessages.help)} />
                </div>
              </ListItem>
            </List>
          </div>
          <div className="profile">
            <div className="photo">
              <img src={UserNoPhoto} alt="" />
            </div>
            <div className="data">
              <div className="name">{user.name}</div>
              <div className="email">{user.email}</div>
            </div>
          </div>
        </div>
        <div className="menu">
          {Object.keys(menus).map((key) => (
            <div key={key}>
              {verifyIfShowMenuBlock(menus[key]) && (
                <div className={classNames("box", `item-menu-${key.toLowerCase()}`)}>
                  <div className="title">{key}</div>
                  {menus[key].map((menu) => (
                    userHasPermissionInMenu(menu)
                    && (
                      <div key={menu.id}>
                        {(!menu.isPrivate // Public
                            || ((menu.isPrivate && !menu.isSuperPrivate) && user.admin) // View only 'admins'
                            || (menu.isSuperPrivate && user.super_admin)) // View only 'super admins'
                          && (
                            <div className="content">
                              <List>
                                <ListItem
                                  button
                                  disableRipple
                                  {...!menu.component ? { component: Link, to: menu.link } : {}}
                                  onClick={(() => {
                                    if (menu.component === "modalContactUs") {
                                      return () => setOpenContactUs(true);
                                    }
                                    if (menu.component === "installPWA") {
                                      // Check if is IOS
                                      if (navigator.userAgent.match(/iPad|iPhone|iPod/i)) {
                                        return () => setShowPromptIOSPWA(true);
                                      }

                                      // Android and PC browser
                                      return handleInstallPWA;
                                    }

                                    return () => setOpenDrawer(false);
                                  })()}
                                  aria-hidden="true"
                                >
                                  <ListItemIcon>{screen.platform === "mobile" && menu.iconMobile ? menu.iconMobile
                                    : menu.icon}
                                  </ListItemIcon>
                                  <ListItemText primary={menu.text} />
                                </ListItem>
                              </List>
                            </div>
                          )}
                      </div>
                    )
                  ))}
                  <Divider />
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="logout">
          <List>
            <ListItem
              button
              disableRipple
              onClick={() => {
                setOpenDrawer(false);
                signOut();
              }}
              aria-hidden="true"
            >
              <ListItemIcon><ExitToAppIcon /></ListItemIcon>
              <ListItemText primary={t(MenuMessages.exit)} />
            </ListItem>
          </List>
        </div>
        <PWAPrompt
          debug={showPromptIOSPWA}
          promptOnVisit={0}
          copyTitle="Instalar o aplicativo"
          copyBody="Para adicionar o aplicativo siga os seguintes passos"
          copyShareButtonLabel="1) Pressione o botão 'Compartilhar'"
          copyAddHomeButtonLabel="2) Pressione o botão 'Adicionar a Tela inicial'"
          copyClosePrompt="Cancelar"
          delay={200}
          onClose={() => setShowPromptIOSPWA(false)}
        />
      </Drawer>
    );
  };

  /**
   * Render survey
   */
  const surveyContainer = useMemo(() => {

    if (!_.isEmpty(user.current_survey) && user.current_survey.show) {

      // Set show to false to not show again until next login
      // Update user data on local storage
      user.current_survey.show = false;
      localStorage.setItem("@Fleet:currentSurvey", JSON.stringify(user.current_survey));

      return (
        <Survey
          surveyPlatform={SurveyPlatform.ZOHO}
          surveyId={user.current_survey.external_id}
          email={user.email}
          additionalInfo={{
            "hostname": window.location.hostname
          }}
        />
      );
    }

    return null;

  }, [user]);

  // endregion Functions
  // region Effects

  // Verify if filter is active to show filtered vehicles/alerts or all of them
  useEffect(() => {
    if (filtersDashboard.active) {
      setVehicles(vehiclesStates.filtered as VehiclesStatesObj);
      setAlerts(alertsStates.filtered as AlertsStateObj);
    } else {
      setVehicles(vehiclesStates.all);
      setAlerts(alertsStates.all);
    }

  }, [filtersDashboard, vehiclesStates, alertsStates]);

  // Get block date
  useEffect(() => {

    const getBlockDate = async () => {

      try {
        const { data } = await api.get("params/read");

        if (data.status === "success") {
          const loadedParams = data.result as Param[];
          const blockValue = loadedParams.find((param) => param.name === ParamsNames.BLOCK_DATE);

          if (blockValue?.key && blockValue.key !== "") {
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

            if (dateRegex.test(blockValue.key)) {
              const date = new Date(blockValue.key);
              const differenceInDays = dateFns.differenceInCalendarDays(date, new Date());

              setDaysOfBlock(differenceInDays);

              if (differenceInDays < 0 && !user.super_admin) {
                setIsBlockDay(true);
              }
            }
          }
        }
      } catch (error) {
        if (!error.response) {
          addToast(
            { type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) }
          );
        } else {
          addToast(
            { type: "error", title: error.response.data.backend, description: error.response.data.message }
          );
        }
      }
    };

    getBlockDate();
  }, [user.super_admin]);

  // Adjust padding top of page content according to the header height
  useEffect(() => {

    const pageElement = document.querySelector(".page") as HTMLElement;
    const alertElement = document.querySelector(".alertBlockDay") as HTMLElement;

    if (pageElement && alertElement) {

      // Store the original padding-top of .page
      const originalPaddingTop = window.getComputedStyle(pageElement).paddingTop;
      const alertHeight = alertElement.offsetHeight;

      // Adjust the padding-top to accommodate the alert
      pageElement.style.paddingTop = `calc(${originalPaddingTop} + ${alertHeight}px)`;

      // Clean up the effect when the component is unmounted
      return () => {
        pageElement.style.paddingTop = originalPaddingTop;
      };
    }

    return undefined;

  }, [daysOfBlock]);

  // endregion Effects

  return (
    <>
      <DialogConfirmAction
        open={isBlockDay}
        onClose={() => signOut()}
        title={t(GlobalMessages.noAccess)}
        actions={[
          { text: t(GlobalMessages.close), action: () => signOut() }
        ]}
      />
      {surveyContainer}
      <Backdrop
        className={style.backdrop}
        open={Object.keys(openPoppers).some((k) => openPoppers[k])}
        onClick={() => handlePopper("closeAll")}
      />
      <Container id="containerHeader">
        {screen.platform === ScreenPlatform.DESKTOP ? renderAppBarDesktop() : renderAppBarMobile()}
        {openContactUs && <ModalContactUs onClose={() => setOpenContactUs(false)} />}
        {openModalIconLegend && <ModalHelp onClose={() => setOpenModalIconLegend(false)} />}
        {renderDrawer()}
      </Container>
      <ModalMapAllVehicles
        vehicles={MergeVehicleStatesLists(vehicles.list, aggregateTrucksStates.all.list)}
        open={openModalMapAllVehicles}
        onClose={() => setOpenModalMapAllVehicles(false)}
      />
      <PopperAlerts
        loading={alerts.loading}
        open={openPoppers.alertsVehicles}
        alerts={allAlerts}
        anchorEl={document.getElementById("actionPopperAlerts")}
      />
      <PopperVehiclesInMaintenance
        loading={vehicles.loading}
        open={openPoppers.vehiclesInMaintenance}
        vehicles={vehicles.list["Veiculos em manutencao"]}
        anchorEl={document.getElementById("actionPopperVehiclesInMaintenance")}
      />
      <PopperVehiclesInSafePlace
        loading={vehicles.loading}
        open={openPoppers.vehiclesInSafePlace}
        vehicles={vehicles.list["Em local confiavel"]}
        anchorEl={document.getElementById("actionPopperVehiclesInSafePlace")}
      />
      <PopperFilterVehiclesDashboard
        open={openPoppers.filterVehiclesDashboard}
        onClose={() => handlePopper("closeAll")}
        anchorEl={document.getElementById("actionPopperFilterVehiclesDashboard")}
      />
      <PopperImproperDischarge
        loading={alerts.loading}
        open={openPoppers.improperDischargesVehicles}
        improperDischarges={improperDischargeAlerts}
        anchorEl={document.getElementById("actionPopperImproperDischarge")}
      />
      <PopperBlockedVehicles
        loading={vehicles.loading}
        open={openPoppers.blockedVehicles}
        vehicles={vehicles.list["Veiculos bloqueados"]}
        anchorEl={document.getElementById("actionPopperBlockedVehicles")}
      />
      <PopperConcreteExpiration
        loading={alerts.loading}
        open={openPoppers.concreteExpiration}
        alerts={concreteExpirationAlerts}
        anchorEl={document.getElementById("actionPopperConcreteExpiration")}
      />
      <PopperSpeedingAlerts
        loading={alerts.loading}
        open={openPoppers.speedingAlertsVehicles}
        alerts={speedingAlerts}
        anchorEl={document.getElementById("actionPopperSpeedingAlerts")}
      />
    </>
  );
};

export default Header;
