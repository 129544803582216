export enum VehicleTypes {
  BETONEIRA = "Betoneira",
  CAMINHAO_BOMBA = "Caminhão bomba",
  VEICULO_DE_APOIO = "Veiculo de apoio",
  PA_CARREGADEIRA = "Pa carregadeira",
  CARRETA_AGREGADOS = "Carreta de agregados",
  CARRETA_SILO = "Carreta silo"
}

export enum VehicleTypesID {
  BETONEIRA = "2768cabf-89aa-4dd3-8984-32a5317c9447",
  CAMINHAO_BOMBA = "02c4ca89-3ba3-46b6-9298-91d27319e43c",
  VEICULO_DE_APOIO = "6fe34fae-a708-4949-9e82-d263a215d96f",
  PA_CARREGADEIRA = "3f1e2153-25de-467a-8fe4-959ad1b2550c",
  CARRETA_AGREGADOS = "564e40ec-0bf2-488f-9091-737f11bfbb83",
  CARRETA_SILO = "6b3b4dce-ef00-42d4-bf59-94c33aed9d16"
}

export function GetVehicleTypeIdByDescription(description: string): string {
  switch (description) {
  case VehicleTypes.BETONEIRA:
    return VehicleTypesID.BETONEIRA;
  case VehicleTypes.CAMINHAO_BOMBA:
    return VehicleTypesID.CAMINHAO_BOMBA;
  case VehicleTypes.VEICULO_DE_APOIO:
    return VehicleTypesID.VEICULO_DE_APOIO;
  case VehicleTypes.PA_CARREGADEIRA:
    return VehicleTypesID.PA_CARREGADEIRA;
  case VehicleTypes.CARRETA_AGREGADOS:
    return VehicleTypesID.CARRETA_AGREGADOS;
  case VehicleTypes.CARRETA_SILO:
    return VehicleTypesID.CARRETA_SILO;
  default:
    return "";
  }
}
