/* eslint-disable camelcase */
/* eslint-disable no-shadow */

import { Vehicle } from "@shared/interfaces/vehicle.interface";

// Action types
export enum VehiclesStatesTypes {
  SET_VEHICLES_STATES = "@vehiclesStates/SET_VEHICLES_STATES",
  SET_VEHICLES_STATES_ALL = "@vehiclesStates/SET_VEHICLES_STATES_ALL",
  SET_VEHICLES_STATES_FILTERED = "@vehiclesStates/SET_VEHICLES_STATES_FILTERED"
}

// Data Types
export interface VehicleStatesList {
  [key: string]: Vehicle[];
}
export interface VehiclesStatesObj {
  list: VehicleStatesList;
  loading: boolean;
}

// State type
export interface VehiclesStatesState {
  all: VehiclesStatesObj;
  filtered?: VehiclesStatesObj;
}

export interface SectionsMenuOptions {
  idSection: number;
  selectedIndex: number;
}

export function MergeVehicleStatesLists(list1: VehicleStatesList, list2: VehicleStatesList): VehicleStatesList {
  const mergedList: VehicleStatesList = { ...list1 };

  for (const key in list2) {
    if (list2.hasOwnProperty(key)) {
      if (mergedList[key]) {
        // Concatenate the arrays if the key exists in both objects
        mergedList[key] = [...mergedList[key], ...list2[key]];
      } else {
        // Otherwise, just add the new key and array
        mergedList[key] = list2[key];
      }
    }
  }

  return mergedList;
}
